<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>巡检记录</span>
      </div>
      <div class="search">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="设备名称">
            <el-select size="small" v-model="form.deviceId" clearable placeholder="请选择设备名称">
              <el-option
                v-for="item in device"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select size="small" v-model="form.deviceTypeId" clearable placeholder="请选择设备类型">
              <el-option
                v-for="item in leixin"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="巡检代号">
            <el-select size="small" v-model="form.patrolCodeId" clearable placeholder="请选择巡检代号">
              <el-option
                v-for="item in patrolcode"
                :label="item.taskCode"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="巡视点名称">
            <el-select size="small" v-model="form.xunshidianId" clearable placeholder="请选择巡视点名称">
              <el-option
                v-for="item in xunshidian"
                :label="item.patrolName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设施位置">
            <el-select size="small" v-model="form.weizhiId" clearable placeholder="请选择设施位置">
              <el-option
                v-for="item in weizhi"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-select size="small" v-model="form.zhuangtaiId" clearable placeholder="请选择设备状态">
              <el-option
                v-for="item in zhuangtai"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-select size="small" v-model="form.opzhuangtaiId" clearable placeholder="请选择任务状态">
              <el-option
                v-for="item in opzhuangtai"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="完成时间">
            <el-date-picker
              size="small"
              v-model="form.finishTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="提交人">
            <el-select size="small" v-model="form.submitterId" clearable placeholder="请选择提交人">
              <el-option
                v-for="item in yuangong"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="alias" label="设备别名"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称"></el-table-column>
          <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
          <el-table-column prop="taskCode" label="巡检代号"></el-table-column>
          <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
          <el-table-column prop="facilityName" label="设施位置"></el-table-column>
          <el-table-column prop="deviceStatus" label="设备状态" width="80">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.deviceStatus == 1">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.deviceStatus == 2">异常</el-tag>
              <el-tag v-if="scope.row.deviceStatus == 4">检修中</el-tag>
              <el-tag type="warning" v-if="scope.row.taskStatus == 2">未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="任务状态" width="80">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.taskStatus == 0">未巡检</el-tag>
              <el-tag type="success" v-if="scope.row.taskStatus == 1">已巡检</el-tag>
              <el-tag type="danger" v-if="scope.row.taskStatus == 2">已遗漏</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="时间段" width="160">
            <template slot-scope="scope">
              {{ dateSecond(scope.row.startTime) }} - {{ dateSecond(scope.row.endTime) }}
            </template>
          </el-table-column>
          <el-table-column label="在线/离线">
            <template slot-scope="scope">
              {{ scope.row.reportType==0&&scope.row.taskStatus==2 ? '-' 
              : scope.row.reportType==0 ? '在线提交' 
              : scope.row.reportType==1 ? dayjs(scope.row.realCompleteTime).format('YYYY-MM-DD HH:mm:ss')+'(离线提交)' 
              : '-'}}
            </template>
          </el-table-column>
          <el-table-column prop="submitter" label="提交人">
            <template slot-scope="scope">
              {{ scope.row.submitter ? scope.row.submitter : '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="modifyRemarks" label="备注">
            <template slot-scope="scope">
              {{ scope.row.modifyRemarks ? scope.row.modifyRemarks : '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="completeTime" label="完成时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.completeTime ? dayjs(scope.row.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.deviceStatus == 2" :disabled="roleId == 4"><div @click="overhaul(scope.row)">撤销</div></el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.deviceStatus == 2" :disabled="roleId == 4"><div @click="turnOverhaul(scope.row)">转检修</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      device: [],
      leixin: [],
      patrolcode: [],
      xunshidian: [],
      weizhi: [],
      zhuangtai: [],
      opzhuangtai: [
        // { id: 0, name: '未巡检'},
        { id: 1, name: '已巡检'},
        { id: 2, name: '已遗漏'}
      ],
      yuangong: [],
      form: {
        finishTime:[]
      },
      tableData: [],
      roleId: 0
    }
  },
  methods: {
    dayjs,
    details(row) {
      this.$router.push({ name: 'recordCycleDetail', params: {row: row}})
    },
    overhaul(row) {
      let form = {
        operation: 0,
        patrolDeviceId: row.patrolDeviceId,
        taskDeviceId: row.taskDeviceId
      }
      this.updateStatus(form)
    },
    // 异常记录操作 状态
    updateStatus(form) {
      this.$ajax.post("recordOperation", form).then((res) => {
        this.loadListData()
      })
    },
    // 异常转检修
    turnOverhaul(row) {
      this.loading = true
      setTimeout(()=>{
        this.$router.push({ name: 'cycleTurnAdd', params: {row: row}})
        this.loading = false
      },2000)
    },
    load_device() {
      this.$ajax.post("deviceChecklist", {
        departmentId: 0,
        deviceTypeId: 0,
        staffId: 0,
      }).then((res) => {
        this.device = res.data;
      });
    },
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.leixin = res.data;
      });
    },
    loadTableData() {
      this.$ajax.post('ruleQuery', {
        pageNum: 0,
        pageSize: 0,
      },{
        taskType: 0
      }).then(res => {
        this.patrolcode = res.data
      })
    },
    load_weizhi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.weizhi = res.data;
      });
    },
    load_zhuangtai() {
      this.$ajax.get("statusQueryAll").then((res) => {
        this.zhuangtai = res.data;
      });
    },
    load_xunshidian() {
      this.$ajax.post("patrolList", {
        facilityId: ''
      }).then((res) => {
        this.xunshidian = res.data;
      });
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadListData()
    },
    loadListData() {
      let dates = [] 
      if(this.form.finishTime){
        this.form.finishTime.forEach((i)=>{
          var date = new Date(i)
          dates.push(date.getTime()) 
        })
      }
     
      this.loading = true
      this.$ajax.post('recordTask', {
        taskType: 0,
        pageNum: this.page,
        pageSize: this.size,
        archivesId: this.form.deviceId?this.form.deviceId:null,
        deviceTypeId: this.form.deviceTypeId?this.form.deviceTypeId:null,
        taskRuleId: this.form.patrolCodeId?this.form.patrolCodeId:null,
        patrolId: this.form.xunshidianId?this.form.xunshidianId:null,
        facilityId: this.form.weizhiId?this.form.weizhiId:null,
        deviceStatus: this.form.zhuangtaiId?this.form.zhuangtaiId:null,
        taskStatus: this.form.opzhuangtaiId?this.form.opzhuangtaiId:null,
        startTime: dates?dates[0]:null,
        endTime: dates?dates[1]:null,
        submitterId: this.form.submitterId?this.form.submitterId:null,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s

        let m = (_s / 60) % 60

        let h = (_s / 60 - m) / 60

        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00:00:00'
      }
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.roleId = sessionStorage.getItem('r')
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.loadListData()
    this.load_device()
    this.load_leixing()
    this.load_weizhi()
    this.load_zhuangtai()
    this.load_xunshidian()
    this.load_yuangong()
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
</style>